
import { Component } from 'vue-property-decorator';
import SimpleGeneralForm from '@/components/article/form/SimpleGeneralForm.vue';
import VWrapper from '@/components/shared/VWrapper.vue';
import EditArticleLoader from '@/components/article/EditArticleLoader.vue';
import { mixins } from 'vue-class-component';
import StackedForm from '@/mixins/StackedForm';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import { EditArticle as EditArticleMixin } from '@/mixins/EditArticle';
import { Article } from '@/interfaces/models/Article';
import EditSimpleArticleLoader from '@/components/article/EditSimpleArticleLoader.vue';
import PriceByTypeForm from '@/components/article/form/PriceByTypeForm.vue';
import AvailabilityForm from '@/components/article/form/AvailabilityForm.vue';
import { CustomerGroup } from '@/enums/CustomerGroup';

@Component({
  components: {
    AvailabilityForm,
    PriceByTypeForm,
    EditSimpleArticleLoader,
    EditArticleLoader,
    VWrapper,
    SimpleGeneralForm,
  },
})
export default class EditSimpleArticle extends mixins(Editable, StackedForm, Notification, EditArticleMixin) {
  public $refs!: {
    general: InstanceType<typeof SimpleGeneralForm> & { getData: () => any };
    price: InstanceType<typeof PriceByTypeForm> & { getData: () => any };
    availability: InstanceType<typeof AvailabilityForm> & { getData: () => any };
  };

  public async mounted() {
    this.$startLoading('venue');

    if (this.editing) {
      await this.show({ id: this.id, category: this.$route.params.category });
    }

    this.$stopAllLoading();
  }

  get shouldDisablePriceByType() {
    return this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu;
  }

  get shouldDisableAvailability() {
    return this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu;
  }

  get shouldDisablePreorderInside() {
    return (
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.Subway ||
        this.venue.customerGroup === CustomerGroup.SubwayFr ||
        this.venue.customerGroup === CustomerGroup.SubwayLu)
    );
  }

  get shouldDisablePreorderDelivery() {
    return (
      this.venue &&
      (this.venue.customerGroup === CustomerGroup.SubwayFr || this.venue.customerGroup === CustomerGroup.SubwayLu)
    );
  }

  protected async save() {
    Promise.all(await this.validate()).then(async (res: any) => {
      if (this.isValid(res)) {
        const data: Partial<Article> = {
          ...this.$refs.general.getData(),
        };
        if (this.$refs.price) {
          data.priceByType = { ...this.$refs.price.getData() };
        }
        if (this.$refs.availability) {
          data.availability = { ...this.$refs.availability.getData() };
        }
        data.id = this.active._id;
        data.category = this.$route.params.category;
        await this.update(data);
        this.$router.push({ name: 'article.index', query: { ...this.$route.query } });
      } else {
        this.notifyError('notification.form');
      }
    });
  }

  protected cancel() {
    this.$router.push({ name: 'article.index', query: { ...this.$route.query } });
  }
}
